<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="textChange"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-menu :close-on-content-click="false" offset-y min-width="320">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" @click="init">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-slider
              label="2D旋转"
              v-model="rotate"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="rotate"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
            <v-slider
              label="3D旋转X"
              v-model="rotateX"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="rotateX"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
            <v-slider
              label="3D旋转Y"
              v-model="rotateY"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="rotateY"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
            <v-slider
              label="3D旋转Z"
              v-model="rotateZ"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="rotateZ"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
            <v-slider
              label="倾斜X"
              v-model="skewX"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="skewX"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
            <v-slider
              label="倾斜Y"
              v-model="skewY"
              min="-180"
              max="180"
              @input="change"
            >
              <template slot="append">
                <input
                  v-model="skewY"
                  @change="change"
                  style="width: 32px"
                />deg
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      rotate: 0,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      skewX: 0,
      skewY: 0,
    };
  },
  methods: {
    init() {
      this.rotate = 0;
      this.rotateX = 0;
      this.rotateY = 0;
      this.rotateZ = 0;
      this.skewX = 0;
      this.skewY = 0;
      let vals = this.value.split(" ");
      for (let i in vals) {
        let val = vals[i];
        let matches;
        matches = val.match(/rotate\((-?[0-9]+)deg\)/);
        if (matches) this.rotate = parseInt(matches[1]);
        matches = val.match(/rotateX\((-?[0-9]+)deg\)/);
        if (matches) this.rotateX = parseInt(matches[1]);
        matches = val.match(/rotateY\((-?[0-9]+)deg\)/);
        if (matches) this.rotateY = parseInt(matches[1]);
        matches = val.match(/rotateZ\((-?[0-9]+)deg\)/);
        if (matches) this.rotateZ = parseInt(matches[1]);
        matches = val.match(/skew\((-?[0-9]+)deg,(-?[0-9]+)deg\)/);
        if (matches) {
          this.skewX = parseInt(matches[1]);
          this.skewY = parseInt(matches[2]);
        }
      }
    },
    textChange(value) {
      this.$emit("change", value);
    },
    change() {
      let result = [];
      if (this.rotate != 0) result.push(`rotate(${this.rotate}deg)`);
      if (this.rotateX != 0) result.push(`rotateX(${this.rotateX}deg)`);
      if (this.rotateY != 0) result.push(`rotateY(${this.rotateY}deg)`);
      if (this.rotateZ != 0) result.push(`rotateZ(${this.rotateZ}deg)`);
      if (this.skewX != 0 || this.skewY != 0)
        result.push(`skew(${this.skewX}deg,${this.skewY}deg)`);
      this.$emit("change", `${result.join(" ")}`);
    },
  },
};
</script>
